import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import Keycloak from 'keycloak-js'

Vue.use(VueRouter)
Vue.config.productionTip = false

const router = new VueRouter({
  mode: 'history',
  routes: [
    {path: '/:room', component: App},
  ],
})

const initApp = () => {
  fetch('/api/config')
    .then(res => res.json())
    .then(config => {
      const app = new Vue({
        vuetify,
        router,
        render: h => h(App, {props: {keycloak: keycloak, config: config}})
      })
      app.$mount('#app')
    })
}

const keycloak = new Keycloak('/api/keycloak.json')

keycloak.init({onLoad: 'check-sso', checkLoginIframe: false})
  .then(() => {
    initApp()
  })
  .catch(() => {
    alert('Did you configure CORS correctly?')
    initApp()
  })
